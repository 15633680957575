import { createStore } from 'vuex'
import {FilterMatchMode} from "primevue/api";

 const store = createStore({
    state() {
        return{
            token: '',
            token_decoded: {},
            hide: false,
            rowClass: '',
            filters:  {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'ID': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'GRUPPE': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'TYP/MODEL': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'HERSTELLER': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'ETAGE': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'ZIMMER_NR': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'STANDORT': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'STATUS': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'UNTERNEHMEN': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'INVENTARNUMMER': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'SERVICELEVEL': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            auftragfilters:  {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'ID': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'unternehmen_name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'AUFTRAG': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'erfasser': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'eingang': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'abgeschlossen': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'STATUS': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'FK_ID_PRODUKT': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
        }
    },
    mutations: {
        storeToken (state, payload) {
            state.token = payload;
        },
        storeTokenDecoded (state, payload) {
            state.token_decoded = payload;
        },
        storeRowClass (state, payload) {
            state.rowClass = payload;
        },

        updateFilters(state, { filterName, value }) {
            state.filters[filterName].value = value;
        },
        updateAuftragFilters(state, { filterName, value }) {
            state.auftragfilters[filterName].value = value;
        },

    },
    getters: {
        getHide (state){
           if (state.token_decoded.unternehmen === 'Acticom AG'){
               state.hide = true;
           }
           return state.hide;
        },

        getRowClass (state){
            return state.rowClass;
        },

        getFilters(state) {
            return state.filters;
        },
        getAuftragFilters(state) {
            return state.auftragfilters;
        },
    }
})

export default store;

