export const environment = {
    backend: 'https://actitool-backend-dev.actiportal.ch',
    // backend: 'localhost:4124',
    keycloackUrl: 'https://keycloak.actiportal.ch/',
    // keycloackUrl: 'http://localhost:8081',
     clientId: 'acticom-dev',
    // clientId: 'acticom',
    //   clientId: 'localhost',
    path: 'https://dev.actiportal.ch',
    fileServer: 'https://actitool-filemanager-dev.actiportal.ch',
    // fileServer: 'http://localhost:4127',
    mailServer: 'https://actitool-mailmanager-dev.actiportal.ch'
    //  mailServer: 'http://localhost:3001'
}
