<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Wartungs-Protokoll</h5>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6">
            <label for="PRUEFDATUM">Prüfdatum</label>
<!--            <InputText id="pruefdatum" type="text"/>-->
            <Calendar inputId="pruefdatum" v-model="inspektionObj.DATUM"></Calendar>
          </div>
          <div class="field col-12 md:col-6">
            <label for="INTERVALL">Intervall</label>
<!--            <InputText id="intervall" type="text" v-model="inspektionObj.INTERVALL"/>-->
            <Dropdown id="intervall" v-model="inspektionObj.INTERVALL" :options="intervalle"  placeholder="Intervall" @change="incrementDate(event)"></Dropdown>

          </div>
          <div class="field col-12 md:col-6">
            <label for="NAECHSTE_PRUEFUNG">Nächste Prüfung</label>
            <Calendar inputId="naechste_pruefung" v-model="inspektionObj.NAECHSTE_PRUEFUNG"></Calendar>
          </div>
          <div class="field col-12 md:col-6">
            <label for="FK_ID_MITARBEITER">Prüfer</label>
            <Dropdown id="fk_id_mitarbeiter" v-model="inspektionObj.FK_ID_MITARBEITER" :options="auftragnehmerMitarbeiterCustom"  placeholder="Prüfer"></Dropdown>
          </div>
            <div class="field col-12">
              <label for="NAME">Beschreibung</label>
              <Textarea id="name" rows="4" v-model="inspektionObj.NAME"/>
            </div>
            <div class="field col-12">
              <label>Aktionen</label>


<!--                <MultiSelect v-model="selectedAktionen" :options="templates" optionLabel="NAME" placeholder="Template" :filter="true" class="multiselect-custom">-->
<!--              </MultiSelect>-->
                <Dropdown id="template" v-model="selectedTemplate" optionLabel="NAME"  :options="templates" :filter="true" placeholder="template"/>

            </div>
            <div class="field col-12">
              <Button label="Speichern" @click="onSave"></Button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import DataService from "@/service/DataService";

export default {
  emits: ['close'],
  data() {
    return {
      inspektionObj: {},
      auftragnehmer: null,
      auftraggeber: null,
      auftragnehmerMitarbeiter: null,
      auftragnehmerMitarbeiterCustom: [],
      aktionen: null,
      templates: null,
      selectedAktionen: null,
      selectedTemplate: null,
      inspektionId: null,
      inspektionAktionObj: {},
      produktId: null,
      intervalle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
    }
  },
  dataService: null,
  created() {
    this.dataService = new DataService();
  },
  async mounted() {
    this.produktId = this.$route.params.id;
    this.auftragnehmer = await this.dataService.getAuftragnehmer();
    this.auftraggeber = await this.dataService.getAuftraggeber(this.produktId);
    this.auftragnehmerMitarbeiter = await this.dataService.getAuftragnehmerMitarbeiter();

    this.auftragnehmerMitarbeiter.forEach((el) => {
      let string = `${el.ID} ${el.VORNAME} ${el.NACHNAME}`;
      this.auftragnehmerMitarbeiterCustom.push(string);
    });

    this.aktionen = await this.dataService.getAktionen();
    this.templates = await this.dataService.getTemplates();

  },
  methods: {
    async onSave(){
      this.inspektionObj.FK_ID_AUFTRAGNEHMER = this.auftragnehmer[0].ID;
      this.inspektionObj.FK_ID_AUFTRAGGEBER = this.auftraggeber[0].ID;
      this.inspektionObj.FK_ID_PRODUKT = this.produktId;
      //
      this.inspektionId = await this.dataService.saveInspektion(this.inspektionObj);

      // this.selectedAktionen.forEach( (el) => {
      //     this.inspektionAktionObj.FK_ID_INSPEKTION = this.inspektionId[0].ID
      //     this.inspektionAktionObj.FK_ID_AKTION = el.ID
      //     this.dataService.saveInspektionAktionen(this.inspektionAktionObj);
      //     this.inspektionAktionObj = {};
      // })
      this.inspektionAktionObj.FK_ID_INSPEKTION = this.inspektionId[0].ID;
      this.inspektionAktionObj.FK_ID_TEMPLATE = this.selectedTemplate.ID;
      this.dataService.saveInspektionAktionen(this.inspektionAktionObj);


      this.$emit('close', false);
    },

    async incrementDate(){

      this.inspektionObj.NAECHSTE_PRUEFUNG = new Date(new Date(this.inspektionObj.DATUM).setMonth(new Date(this.inspektionObj.DATUM).getMonth() + this.inspektionObj.INTERVALL));
    }

  }
}
</script>
